import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import SectionImage from '../components/SectionImage'
import BannerInfo from '../components/BannerInfo'
import { useLangContext } from '../context/lang.context'
import { gContainer } from '../style/config/globals'
import Layout from '../components/Layout'
import FormContact from '../page/Contact/FormContact/FormContact'
import ContactPagesAll from '../page/Contact/ContactPagesAll/ContactPagesAll'

export default function Contact({ data, location }) {
  const { lang, setLang } = useLangContext()
  const contact = data.contacts.edges.filter(
    ({ node }) => node.lang.slug === lang
  )[0]?.node

  const urlArray = location?.pathname?.split('/').filter((e) => e)
  const urlLang = urlArray.length > 2 && urlArray[urlArray?.length - 1]
  const langs = ['en', 'pl']

  /* eslint-disable */
  useEffect(() => {
    if (langs.includes(urlLang)) setLang(urlLang)
  }, [])

  useEffect(() => {
    if (!urlLang && lang !== contactLang) setLang(contactLang)
  }, [lang])

  /* eslint-enable */
  if (!contact) return <div />

  const {
    title,
    desc,
    seo_title,
    seo_desc,
    image,
    lang: { slug: contactLang },
    sections,
    slug,
  } = contact

  return (
    <Layout>
      <Seo
        title={seo_title ? seo_title : title}
        description={seo_desc ? seo_desc : desc}
        lang={contactLang}
        image={image.file.url}
      />
      <SectionImage
        title={title}
        desc={desc}
        back={'CARGONITE'}
        image={getImage(image)}
        imagePosition='50% 10%'
      />
      <div css={[gContainer]}>
        {sections?.map(({ title, subtitle, desc, image, points }, id) => (
          <BannerInfo
            key={id}
            title={title}
            subtitle={subtitle}
            desc={desc.desc}
            // points={points}
            image={getImage(image)}
          />
        ))}
      </div>
      <ContactPagesAll page={slug.slug} />
      <FormContact page='contact' />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contacts: allContentfulContact(filter: { slug: { slug: { eq: $slug } } }) {
      edges {
        node {
          lang {
            slug
          }
          slug {
            slug
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
            # title
            file {
              url
            }
          }
          sections {
            desc {
              desc
            }
            title
            subtitle
            # points {
             # content
             # link
             # text
            # }
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
              title
            }
          }
          title
          # headlines
          desc
          seo_title
          # seo_desc
        }
      }
    }
  }
`
